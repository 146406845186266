import {
	handleErrorWithSentry,
	replayIntegration,
	browserTracingIntegration,
} from '@sentry/sveltekit';
import {
	PUBLIC_SENTRY_DSN,
	PUBLIC_SENTRY_REPLAYS_ENABLED,
	PUBLIC_ENVIRONMENT,
	PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
	PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
	PUBLIC_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
} from '$env/static/public';
import { initSentry } from '$lib/utils/sentry';
import './polyfills';
import { tracePropagationTargets } from '$lib/utils.js';

const replays = PUBLIC_SENTRY_REPLAYS_ENABLED === 'true';

function setupSentryTracking(initFunction: typeof initSentry = initSentry): void {
	initFunction({
		dsn: PUBLIC_SENTRY_DSN,
		tracesSampleRate: parseFloat(PUBLIC_SENTRY_TRACES_SAMPLE_RATE),
		tracePropagationTargets,
		replaysSessionSampleRate: replays
			? parseFloat(PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE)
			: undefined,
		replaysOnErrorSampleRate: replays
			? parseFloat(PUBLIC_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE)
			: undefined,
		// If you don't want to use Session Replay, just remove:
		// "integrations: [new Replay()]," by setting PUBLIC_SENTRY_REPLAYS_ENABLED to false
		// we only enable it in prod by default
		integrations: replays
			? [replayIntegration({ maskAllText: false }), browserTracingIntegration()]
			: [browserTracingIntegration()],
		environment: PUBLIC_ENVIRONMENT,
	});
}
setupSentryTracking(initSentry);

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
