import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121')
];

export const server_loads = [0,2,12,4,9,11,23];

export const dictionary = {
		"/(app)": [26,[2]],
		"/(spa)/(brokerage)/accounts-payable": [49,[12,13]],
		"/(spa)/(brokerage)/accounts-receivable": [50,[12,13]],
		"/(spa)/(brokerage)/brokerage": [51,[12,13,14]],
		"/(spa)/(brokerage)/brokerage/settings": [52,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/account": [53,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/billing": [54,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/branding": [55,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/carrier-agreement": [56,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/charge-codes": [57,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/commission": [58,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/dat-integration": [59,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/denim-integration": [60,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/haulpay-integration": [61,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/highway-integration": [62,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/leads": [63,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/macropoint-capacity-integration": [64,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/macropoint-integration": [65,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/mcp-integration": [66,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/quickbooks-online": [67,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/rmis-integration": [68,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/trucker-tools-integration": [69,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/trucker-tools-sourcing-integration": [70,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/truckstop-integration": [71,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/settings/truckstop-trucksearch-integration": [72,[12,13,14,15]],
		"/(spa)/(brokerage)/brokerage/users": [73,[12,13,14]],
		"/(spa)/(brokerage)/carriers": [74,[12,13]],
		"/(spa)/(brokerage)/carriers/lane": [81,[12,13]],
		"/(spa)/(brokerage)/carriers/search": [82,[12,13]],
		"/(spa)/(brokerage)/carriers/[carrierId]": [75,[12,13,16]],
		"/(spa)/(brokerage)/carriers/[carrierId]/activity-log": [76,[12,13,16]],
		"/(spa)/(brokerage)/carriers/[carrierId]/compliance": [77,[12,13,16]],
		"/(spa)/(brokerage)/carriers/[carrierId]/contacts": [78,[12,13,16]],
		"/(spa)/(brokerage)/carriers/[carrierId]/load-history": [79,[12,13,16]],
		"/(spa)/(brokerage)/carriers/[carrierId]/payment-info": [80,[12,13,16]],
		"/(spa)/(brokerage)/commissions": [83,[12,13]],
		"/(public)/(with-layout)/confirmed": [36,[,7],[6]],
		"/(spa)/(brokerage)/customer-management": [84,[12,13,17]],
		"/(spa)/(brokerage)/customer-management/customers": [85,[12,13,17,18]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]": [86,[12,13,19]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/activity-log": [87,[12,13,19]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/contacts": [88,[12,13,19]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/edi-log": [89,[12,13,19]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/lead-info": [90,[12,13,19]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/order-history": [~91,[12,13,19]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/settings/charge-codes": [92,[12,13,19]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/settings/references": [93,[12,13,19]],
		"/(spa)/(brokerage)/customer-management/customers/[shipperId]/shipping-locations": [94,[12,13,19]],
		"/(spa)/(brokerage)/customer-management/quotes": [95,[12,13,17]],
		"/(spa)/(brokerage)/dashboard": [96,[12,13]],
		"/(app)/documents": [27,[2]],
		"/(public)/(with-layout)/forgot-password": [37,[,7],[6]],
		"/(spa)/internal/brokerages": [114,[12,24]],
		"/(spa)/internal/brokerages/[organizationId]": [115,[12,24,25]],
		"/(spa)/internal/brokerages/[organizationId]/activity-log": [116,[12,24,25]],
		"/(spa)/internal/brokerages/[organizationId]/users": [117,[12,24,25]],
		"/(spa)/internal/brokerages/[organizationId]/users/[userId]": [118,[12,24,25]],
		"/(spa)/internal/charge-codes": [119,[12,24]],
		"/(spa)/internal/references": [120,[12,24]],
		"/(spa)/internal/special-requirements": [121,[12,24]],
		"/(app)/loads": [28,[2,3]],
		"/(app)/loads/[loadId]": [29,[2,3,4]],
		"/(app)/location": [30,[2]],
		"/(public)/(with-layout)/login": [38,[,7],[6]],
		"/(app)/logout": [~31,[2]],
		"/(public)/oauth2/truckstop": [40,[],[6]],
		"/(app)/orders": [~32,[2,5]],
		"/(app)/orders/drafts": [~33,[2,5]],
		"/(app)/orders/drafts/[orderId]": [~34,[2,5]],
		"/(app)/orders/old": [35,[2,5]],
		"/(public)/(with-layout)/password-reset/[username]": [~39,[,7],[6]],
		"/(public)/payment-complete": [~41,[],[6]],
		"/(public)/pricing": [~42,[,8],[6]],
		"/(public)/quote/[quoteGroupId]": [43,[,9],[6]],
		"/(spa)/(brokerage)/quoting": [97,[12,13,20]],
		"/(spa)/(brokerage)/quoting/shipper-quotes": [98,[12,13,20]],
		"/(spa)/(brokerage)/quoting/shipper-rates": [99,[12,13,20]],
		"/(spa)/(brokerage)/shipments": [100,[12,13,21]],
		"/(spa)/(brokerage)/shipments/[shipmentId]": [101,[12,13,21,22]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/activity-log": [102,[12,13,21,22]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/check-calls": [103,[12,13,21,22]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/details": [104,[12,13,21,22]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/documents": [105,[12,13,21,22]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/edi-log": [106,[12,13,21,22]],
		"/(spa)/(brokerage)/shipments/[shipmentId]/financials": [107,[12,13,21,22]],
		"/(public)/sign/[dsrId]": [~44,[,10],[6]],
		"/(public)/tracking/[shipmentId]": [45,[,11],[6]],
		"/(public)/tracking/[shipmentId]/check-calls": [46,[,11],[6]],
		"/(public)/tracking/[shipmentId]/documents": [47,[,11],[6]],
		"/(public)/tracking/[shipmentId]/edi-log": [48,[,11],[6]],
		"/(spa)/(brokerage)/vendors": [108,[12,13]],
		"/(spa)/(brokerage)/vendors/[vendorId]": [109,[12,13,23]],
		"/(spa)/(brokerage)/vendors/[vendorId]/activity-log": [110,[12,13,23]],
		"/(spa)/(brokerage)/vendors/[vendorId]/contacts": [111,[12,13,23]],
		"/(spa)/(brokerage)/vendors/[vendorId]/documents": [112,[12,13,23]],
		"/(spa)/(brokerage)/vendors/[vendorId]/locations": [113,[12,13,23]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';